import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import { AccountCircle } from "@mui/icons-material";
import { Image } from "@components/index";
import { useAuth } from "@hooks/useAuth";

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const goToAccount = () => {
    navigate("/account");
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Link to="/">
          <Image src="/logo-nav.png" alt="logo" width={140} />
        </Link>

        {!user ? (
          <Button
            variant="outlined"
            sx={{ my: 1, mx: 1.5 }}
            onClick={goToAccount}
          >
            {t("action.login")}
          </Button>
        ) : (
          <div>
            <IconButton
              size="large"
              aria-label="user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              onMouseEnter={handleMenu}
              onMouseLeave={handleMenu}
            >
              <AccountCircle />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={goToAccount}>{t("account.title")}</MenuItem>
              <MenuItem onClick={handleLogout}>{t("action.logout")}</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
