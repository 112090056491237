import * as React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import { Image, PageContainer } from "@components/index";
import Copyright from "@components/Footer/Copyright";
import { useAuth } from "@hooks/useAuth";
import { localLogin } from "@api/auth";

export default function Login() {
  const { t } = useTranslation();
  const { login } = useAuth();
  const [snackState, setSnackState] = React.useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = new FormData(event.currentTarget);

    const { success, data, message } = await localLogin({
      email: form.get("email") as string,
      password: form.get("password") as string,
    });
    if (success && data) {
      login(data, "/account");
    } else {
      setSnackState({ open: true, message });
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackState({ open: false, message: "" });
  };

  return (
    <PageContainer pageTitle="auth.login" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Image
          src="/icon-transparent.png"
          alt="logo"
          sx={{ marginBottom: 2 }}
        />
        <Typography component="h1" variant="h5">
          {t("auth.login")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("profile.email")}
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("profile.password")}
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("action.login")}
          </Button>

          <Grid item xs>
            <Link href="#" variant="body2">
              {t("auth.forget_password")}
            </Link>
          </Grid>
        </Box>
      </Box>

      <Copyright sx={{ mt: 8 }} />

      <Snackbar
        open={snackState.open}
        message={snackState.message}
        autoHideDuration={4000}
        onClose={handleClose}
      />
    </PageContainer>
  );
}
