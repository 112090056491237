import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

type UserData = { jwt: string; user: any };

interface AuthContextInterface {
  token: string;
  user: UserProfile;
  login: (d: UserData, route?: string) => void;
  logout: () => void;
}

interface UserProfile {
  id: number;
  username: string;
  email: string;
  phone?: string;
  avatar?: string;
  confirmed: boolean;
  level: number;
}

const initProfile: UserProfile = {
  id: 0,
  avatar: "",
  username: "",
  email: "",
  phone: "",
  confirmed: false,
  level: 0,
};

const AuthContext = createContext<AuthContextInterface>({
  token: "",
  user: initProfile,
  login: () => {},
  logout: () => {},
});

export const USER_KEY = "btv-user";
export const TOKEN_KEY = "btv-auth";

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [user, setUser] = useLocalStorage(USER_KEY, null);
  const [token, setToken] = useLocalStorage(TOKEN_KEY, null);
  const navigate = useNavigate();

  const login = async (data: UserData, route?: string) => {
    // pick user info
    const { id, username, email, avatar, phone, provider, confirmed, level } =
      data.user;
    setUser({
      id,
      username,
      email,
      avatar,
      phone,
      provider,
      confirmed,
      level,
    });
    setToken(data.jwt);

    if (route) {
      navigate(route);
    }
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    navigate("/", { replace: true });
  };

  return (
    <AuthContext.Provider value={{ token, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
