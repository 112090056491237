import { useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container, { ContainerProps } from "@mui/material/Container";
import { Header, Footer } from "@components/index";

interface PageContainerProps extends ContainerProps {
  children: React.ReactNode;
  pageTitle?: string;
  hideHeader?: boolean;
  hideFooter?: boolean;
}

export default function PageContainer({
  children,
  pageTitle,
  hideHeader,
  hideFooter,
  ...props
}: PageContainerProps) {
  const { t } = useTranslation();

  // Scroll to top on route change
  useLayoutEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, [pageTitle]);

  return (
    <>
      <Helmet>
        <title>
          {pageTitle ? `${t(pageTitle)} | ${t("site_name")}` : t("site_name")}
        </title>
      </Helmet>
      {!hideHeader && <Header />}
      <Container component="main" {...props}>
        {children}
      </Container>
      {!hideFooter && <Footer />}
    </>
  );
}
