import { useLocation, Navigate } from "react-router-dom";
import { useAuth } from "@hooks/useAuth";

export function ProtectedRoute({ children }: { children: JSX.Element }) {
  let { user } = useAuth();
  let location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}
