// @ts-nocheck

import axios from "axios";
import { TOKEN_KEY } from "@hooks/useAuth";

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

API.interceptors.request.use(
  async (config) => {
    const token = await window.localStorage.getItem(TOKEN_KEY);
    const tokenStr = JSON.parse(token) ? JSON.parse(token).toString() : "";

    if (tokenStr) {
      config.headers.Authorization = `Bearer ` + tokenStr;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getError = (error: any) => {
  let message = "Failed to load";
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    const serverMsg = error.response.data.length
      ? error.response.data[0]?.message
      : error.response.data.message;

    if (serverMsg) {
      message = serverMsg;
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }

  if (typeof message === "string") {
    return message;
  } else if (message.length !== 0) {
    const msgObj = message[0];
    if (msgObj && msgObj.messages) {
      return msgObj.messages[0].message;
    }
  }
};
