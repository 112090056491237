import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { AlertDialog, PageContainer } from "@components/index";
import { useAuth } from "@hooks/useAuth";
import { useSubscription } from "@hooks/useSubscription";

type PlanType = typeof PLANS[number];

export const PLANS = [
  {
    tier: "T1",
    title: "優惠會籍",
    subheader: "一年會籍",
    price: 488,
    description: [
      "任看超過100本並持續更新的迷你課",
      "優惠價錢參加線下活動及書友聚會",
    ],
    buttonText: "App內訂閱",
    buttonVariant: "outlined",
  },
  {
    tier: "T2",
    title: "高級會籍",
    subheader: "永久會籍",
    price: 1888,
    price_description: "已包含$488一年會籍",
    description: [
      "一次性升級，永久享有高級會籍服務",
      "任看超過100本並持續更新的迷你課",
      "任看精華・品書會精彩內容",
      "優惠價錢參加線下活動及書友聚會",
    ],
    buttonText: "立即報名",
    buttonVariant: "contained",
  },
  {
    tier: "T3",
    title: "VIP會籍",
    subheader: "永久會籍",
    price: 5888,
    price_description: "已包含$488一年會籍",
    description: [
      "一次性升級，永久享有VIP會籍服務",
      "任看超過100本並持續更新的迷你課",
      "任看精華・品書會精彩內容",
      "任看所有精品課（包持陸續上架的精品課）",
      "免費參加線下活動及書友聚會",
    ],
    buttonText: "升級計劃",
    buttonVariant: "outlined",
  },
];

function PricingContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { subscriptionHistories } = useSubscription(user?.id);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const goToCheckout = (plan: PlanType) => {
    if (plan.tier === "T1") {
      setDialogOpen(true);
    } else {
      navigate("/checkout", {
        state: {
          tier: plan.tier,
          title: plan.title,
          subheader: plan.subheader,
          price: plan.price,
        },
      });
    }
  };

  const CardButton = ({ plan }: { plan: PlanType }) => {
    let isSubscribed = false;

    if (subscriptionHistories.length !== 0) {
      isSubscribed = subscriptionHistories.some(
        (s) => s.subscription.tier === plan.tier
      );
    }

    return (
      <Button
        fullWidth
        variant={
          isSubscribed
            ? "outlined"
            : (plan.buttonVariant as "outlined" | "contained")
        }
        sx={{ mb: 2, mx: 1 }}
        onClick={() => goToCheckout(plan)}
        disabled={isSubscribed}
      >
        {isSubscribed ? "已訂閲" : plan.buttonText}
      </Button>
    );
  };

  return (
    <PageContainer pageTitle="">
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          {t("pricing.header")}
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          component="p"
        >
          {t("pricing.subheader")}
        </Typography>
      </Container>
      {/* End hero unit */}

      <Container>
        <Grid container spacing={5} alignItems="flex-start">
          {PLANS.map((plan) => (
            <Grid
              item
              key={plan.tier}
              xs={12}
              sm={plan.tier === "T3" ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={plan.title}
                  titleTypographyProps={{ align: "center", fontSize: "1.2rem" }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent sx={{ minHeight: { md: 385, sm: 240 } }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      color="text.primary"
                    >
                      ${plan.price}
                    </Typography>
                    {plan.tier === "T1" && (
                      <Typography variant="h6" color="text.secondary">
                        /年
                      </Typography>
                    )}
                  </Box>
                  <Typography
                    sx={{ textAlign: "center" }}
                    color="text.secondary"
                  >
                    {plan.price_description}
                  </Typography>

                  <ul>
                    {plan.description.map((line) => (
                      <Typography key={line} component="li" variant="subtitle1">
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>

                <CardActions>
                  <CardButton plan={plan} />
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <AlertDialog
        open={dialogOpen}
        setDialogOpen={(val) => setDialogOpen(val)}
        title="pricing.iap_alert.title"
        content="pricing.iap_alert.content"
        buttons={[
          {
            label: "action.later",
          },
          {
            label: "action.go",
            onClick: () => navigate("/download"),
          },
        ]}
      />
    </PageContainer>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
