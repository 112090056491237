import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface AlertDialogProps {
  open: boolean;
  setDialogOpen: (val: boolean) => void;
  title: string;
  content?: string;
  buttons?: { label: string; onClick?: () => void }[];
}

export default function AlertDialog({
  open,
  setDialogOpen,
  title,
  content,
  buttons,
}: AlertDialogProps) {
  const { t } = useTranslation();

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{t(title)}</DialogTitle>
      {!!content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(content)}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {buttons ? (
          buttons.map((btn) => (
            <Button
              key={btn.label}
              onClick={() => {
                handleClose();
                if (btn.onClick) {
                  btn.onClick();
                }
              }}
            >
              {t(btn.label)}
            </Button>
          ))
        ) : (
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
