import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { PageContainer } from "@components/index";

interface PageProps {
  title?: string;
  message?: string;
  buttonLabel?: string;
}

export default function Page({ title, message, buttonLabel }: PageProps) {
  const { t } = useTranslation();

  return (
    <PageContainer sx={{ height: "80vh" }}>
      <Box
        sx={{
          mt: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          {t(title || "error.coming_soon")}
        </Typography>
        {message && <Typography component="p">{t(message)}</Typography>}

        <Button href="/" variant="contained" sx={{ mt: 10 }}>
          {t(buttonLabel || "action.home")}
        </Button>
      </Box>
    </PageContainer>
  );
}
