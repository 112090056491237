import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { PageContainer } from "@components/index";
import { useAuth } from "@hooks/useAuth";
import { useSubscription } from "@hooks/useSubscription";
import { createPortal } from "@api/subscription";

function Account() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { activeSubscription, subscriptionHistories } = useSubscription(
    user.id
  );

  // TODO
  // const onEditProfile = () => {};

  const goToStripePortal = async () => {
    try {
      const { data } = await createPortal({});
      if (data) {
        window.location.href = data;
      }
    } catch (error) {}
  };

  const getUpgradeDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return new Date(
      date.setFullYear(date.getFullYear() + 1)
    ).toLocaleDateString();
  };

  const getRenewalDate = () => {
    const T1Sub = subscriptionHistories.find(
      (s) => s.subscription.tier === "T1"
    );
    if (T1Sub) {
      return new Date(T1Sub.created_at).toLocaleDateString();
    }
  };

  return (
    <PageContainer pageTitle="account.title" maxWidth="sm">
      <Box sx={{ marginTop: 2 }}>
        <Typography component="h1" variant="h5">
          {t("account.title")}
        </Typography>
        <Divider sx={{ marginTop: 1 }} />

        <List sx={{ marginBottom: 4 }}>
          <ListItem
          // secondaryAction={
          //   <Button onClick={() => onEditProfile()}>
          //     {t("profile.edit_profile")}
          //   </Button>
          // }
          >
            <ListItemText
              primary={user.username}
              secondary={`${t("profile.member_id")}: ${user.id}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={t("profile.email")} secondary={user.email} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t("profile.phone")}
              secondary={user.phone || "-"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t("profile.password")}
              secondary="********"
            />
          </ListItem>
        </List>

        <Typography component="h1" variant="h5">
          {t("subscription.title")}
        </Typography>
        <Divider sx={{ marginTop: 1 }} />

        <List>
          {activeSubscription ? (
            <>
              <ListItem
              // secondaryAction={
              //   <Button onClick={() => onEditProfile()}>
              //     {t("subscription.manage_subscription")}
              //   </Button>
              // }
              >
                <ListItemText
                  primary={activeSubscription.subscription.name}
                  secondary={`${t(
                    "subscription.upgrade_date"
                  )}: ${getUpgradeDate(activeSubscription.created_at)}`}
                />
              </ListItem>
              {activeSubscription && (
                <ListItem sx={{ mt: -2 }}>
                  <ListItemText
                    primary=""
                    secondary={`${t(
                      "subscription.renew_date"
                    )}: ${getRenewalDate()}`}
                  />
                </ListItem>
              )}
            </>
          ) : (
            <ListItem>
              <ListItemText primary={t("subscription.empty_subscription")} />
            </ListItem>
          )}

          <ListItem
            secondaryAction={
              <Button onClick={() => goToStripePortal()}>
                {t("payment.title")}
              </Button>
            }
          >
            <ListItemIcon>
              <CreditCardIcon />
            </ListItemIcon>
            <ListItemText secondary="**** **** **** ****" />
          </ListItem>
        </List>
      </Box>
    </PageContainer>
  );
}

export default Account;
