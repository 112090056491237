import Box, { BoxProps } from "@mui/material/Box";

interface ImageProps extends BoxProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  imgStyle?: any;
}

function Image({
  src,
  width = 100,
  height,
  alt,
  imgStyle,
  ...rest
}: ImageProps) {
  return (
    <Box {...rest}>
      <img src={src} width={width} height={height} alt={alt} style={imgStyle} />
    </Box>
  );
}

export default Image;
