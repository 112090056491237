import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import { AuthProvider } from "@hooks/useAuth";
import Account from "./pages/Account";
import Checkout from "./pages/Checkout";
import Pricing from "./pages/Pricing";
import Login from "./pages/Login";
import Download from "./pages/Download";
import Page from "./pages/EmptyPage";
import { theme } from "./theme";
import { ProtectedRoute } from "./ProtectedRoute";
import ErrorBoundary from "./ErrorBoundary";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<Pricing />} />
            <Route path="membership" element={<Pricing />} />
            <Route path="login" element={<Login />} />
            <Route path="checkout" element={<Checkout />} />
            <Route
              path="account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route path="download" element={<Download />} />
            <Route path="about" element={<Page />} />
            <Route path="terms" element={<Page />} />
            <Route path="privacy-policy" element={<Page />} />
            <Route path="*" element={<Page title="error.404" />} />
          </Routes>
        </ErrorBoundary>
      </AuthProvider>
    </ThemeProvider>
  );
}
