import { API, getError } from "./index";

export const getSubscriptionPlans = async () => {
  try {
    const { data }: any = await API.get(`/subscriptions?_sort=id:ASC`);
    return {
      success: true,
      data,
    };
  } catch (error) {
    const message = getError(error);
    return {
      success: false,
      message,
    };
  }
};

export const getPurchasedSubscription = async (userId: number) => {
  try {
    const { data }: any = await API.get(
      `/subscription-purchases?user=${userId}&subscription_null=false`
    );
    return {
      success: true,
      data,
    };
  } catch (error) {
    const message = getError(error);
    return {
      success: false,
      message,
    };
  }
};

export const createCheckout = async ({ tier }: any) => {
  try {
    const { data }: any = await API.post(
      `/subscription-purchases/create-checkout-session`,
      {
        tier,
      }
    );
    return {
      success: true,
      data,
    };
  } catch (error: any) {
    const message = getError(error);
    return {
      success: false,
      message,
    };
  }
};

export const createPortal = async ({ sessionId }: any) => {
  try {
    const { data }: any = await API.post(
      `/subscription-purchases/create-portal-session`,
      {
        session_id: sessionId,
      }
    );
    return {
      success: true,
      data,
    };
  } catch (error: any) {
    const message = getError(error);
    return {
      success: false,
      message,
    };
  }
};
