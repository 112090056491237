import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { PageContainer, Image } from "@components/index";

export default function Download() {
  const { t } = useTranslation();

  return (
    <PageContainer
      pageTitle="download.title"
      sx={{ minHeight: "80vh" }}
      maxWidth="md"
    >
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          padding={{ xs: 5, md: 0 }}
          display="flex"
          direction="column"
          justifyContent="center"
          alignItems={{ md: "flex-start", xs: "center" }}
        >
          <Typography
            component="h1"
            variant="h1"
            color="text.primary"
            gutterBottom
          >
            {t("pricing.header")}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            component="p"
            textAlign={{ md: "left", xs: "center" }}
          >
            {t("pricing.subheader")}
          </Typography>

          <Stack direction="row" flexWrap="wrap" sx={{ mt: 8 }}>
            <Button sx={{ mr: 2 }}>
              <Image
                src="images/apple-store-badge-en.svg"
                alt="app store"
                width={140}
              />
            </Button>
            <Button>
              <Image
                src="images/google-play-badge-en.png"
                alt="play store"
                width={160}
              />
            </Button>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
        >
          <Image
            src="images/iphone13-portrait.png"
            alt="iphone"
            imgStyle={{ width: "auto", height: "80vh" }}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
}
