import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import Typography from "@mui/material/Typography";
import Copyright from "./Copyright";

const FOOTERS = [
  {
    title: "懶人讀書會",
    links: [
      {
        path: "/membership",
        label: "pricing.title",
      },
      {
        path: "/about",
        label: "about.title",
      },
      {
        path: "/account",
        label: "account.title",
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        path: "/privacy-policy",
        label: "privacy.title",
      },
      {
        path: "/terms",
        label: "terms.title",
      },
      {
        path: "/download",
        label: "download.title",
      },
    ],
  },
];

const CustomUl = styled.ul`
  list-style: none;
`;

const CustomLi = styled.li`
  margin-bottom: 1.1rem;
  a {
    text-decoration: none;
    font-size: 0.9rem;
  }
`;

function Footer() {
  const { t } = useTranslation();

  return (
    <Container
      maxWidth="md"
      component="footer"
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        mt: 8,
        py: [3, 6],
      }}
    >
      <Grid container spacing={4} justifyContent="flex-start">
        {FOOTERS.map((f) => (
          <Grid item xs={6} sm={3} key={f.title}>
            <CustomUl>
              {f.links.map((item) => (
                <CustomLi key={item.path}>
                  <Link to={item.path}>
                    <Typography component="p" color="text.secondary">
                      {t(item.label)}
                    </Typography>
                  </Link>
                </CustomLi>
              ))}
            </CustomUl>
          </Grid>
        ))}
        <Grid item xs={6} sm={3} sx={{ ml: "1rem" }}>
          <MuiLink
            href="https://www.facebook.com/BookTV.hk"
            target="_blank"
            sx={{ mr: 2 }}
          >
            <FacebookRoundedIcon fontSize="large" color="action" />
          </MuiLink>
          <MuiLink
            href="https://www.instagram.com/booktv.hk/"
            target="_blank"
            sx={{ mr: 2 }}
          >
            <InstagramIcon fontSize="large" color="action" />
          </MuiLink>
          <MuiLink
            href="https://www.youtube.com/channel/UCRZGzACx4HfnmhXGrBidU5w"
            target="_blank"
          >
            <YouTubeIcon fontSize="large" color="action" />
          </MuiLink>
        </Grid>
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}

export default Footer;
