import { API, getError } from "./index";

type AuthResponse = {
  data: { jwt: string; user: any };
};

type LoginParams = {
  email: string;
  password: string;
};

export const localLogin = async ({ email, password }: LoginParams) => {
  try {
    const { data }: AuthResponse = await API.post("/auth/local", {
      identifier: email,
      password,
    });

    return {
      success: true,
      data,
    };
  } catch (error: any) {
    const message = getError(error);
    return {
      success: false,
      message,
    };
  }
};

export const getUserProfile = async (token?: string) => {
  try {
    const { data }: any = await API.get(`/users/me`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return {
      success: true,
      data,
    };
  } catch (error: any) {
    const message = getError(error);
    return {
      success: false,
      message,
    };
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const response: any = await API.post(`/auth/forgot-password`, {
      email,
    });
    return {
      success: response.status === 200,
      data: response.data,
    };
  } catch (error: any) {
    const message = getError(error);
    return {
      success: false,
      message,
    };
  }
};
