import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import PageContainer from "@components/PageContainer";
import { createCheckout } from "@api/subscription";
import { useAuth } from "@hooks/useAuth";
import { useSubscription, SubscriptionHistory } from "@hooks/useSubscription";
import { PLANS } from "@pages/Pricing";
import { getUserProfile } from "@api/auth";

interface StateType {
  title: string;
  subheader?: string;
  tier: string;
  price: number;
  subscriptionHistories?: SubscriptionHistory[];
}

const ProductSummary = ({
  tier,
  title,
  price,
  subscriptionHistories,
}: StateType) => {
  const { t } = useTranslation();
  let hasT1 = false;
  let hasT2 = false;
  let finalPrice = price;

  if (subscriptionHistories) {
    subscriptionHistories.forEach((s) => {
      if (s.subscription.tier === "T1" && tier !== "T1") {
        hasT1 = true;
      }
      if (s.subscription.tier === "T2" && tier !== "T2") {
        hasT2 = true;
      }
    });
    if (hasT1) {
      finalPrice -= PLANS[0].price;
    }
    if (hasT2) {
      finalPrice -= PLANS[1].price - PLANS[0].price;
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" mb={2}>
            <Typography>{title}</Typography>
            <Typography textAlign="right">{`$${price}`}</Typography>
          </Stack>

          {hasT2 && (
            <Stack direction="row" justifyContent="space-between">
              <Typography color="text.secondary">- 高級會籍</Typography>
              <Typography color="text.secondary">
                ${PLANS[1].price - PLANS[0].price}
              </Typography>
            </Stack>
          )}

          {(hasT1 || hasT2) && (
            <Stack direction="row" justifyContent="space-between">
              <Typography color="text.secondary">- 一年會籍</Typography>
              <Typography color="text.secondary">${PLANS[0].price}</Typography>
            </Stack>
          )}
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {t("subscription.upgrade_plan")}
            </Typography>
            <Typography variant="h6">{`$${finalPrice}`}</Typography>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

function Checkout() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, login } = useAuth();
  const { subscriptionHistories } = useSubscription(user?.id);
  let [product, setProduct] = useState<StateType>({
    tier: "",
    title: "",
    subheader: "",
    price: 0,
  });
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");
  const [snackState, setSnackState] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    // Redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id") as string);
    }
    if (query.get("canceled")) {
      setSuccess(false);
      setSnackState({
        open: true,
        message: "付款已取消",
      });
    }
  }, [sessionId]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const product = query.get("product");
    const token = query.get("redirect_token");

    const autoLogin = async (t: string) => {
      const { data } = await getUserProfile(t);
      login({ jwt: t, user: data });
    };

    if (token) {
      autoLogin(token);
    } else if (!token && !user) {
      navigate("/login", { replace: true });
    }

    if (!location.state && product) {
      const plan = PLANS.find((p) => p.tier === product);
      if (plan) {
        const { tier, title, subheader, price } = plan;
        setProduct({ tier, title, subheader, price });
      }
    } else {
      setProduct(location.state as StateType);
    }
    // eslint-disable-next-line
  }, [location]);

  const confirmCheckout = async () => {
    try {
      const { data, message } = await createCheckout({ tier: product.tier });
      if (data) {
        window.location.href = data;
      } else {
        setSnackState({
          open: true,
          message,
        });
      }
    } catch (error) {
      setSnackState({
        open: true,
        message: "Payment error",
      });
    }
  };

  const handleClose = () => {
    setSnackState({ open: false, message: "" });
  };

  const ProductCard = () => (
    <ProductSummary
      tier={product.tier}
      title={product.title}
      subheader={product.subheader}
      price={product.price}
      subscriptionHistories={subscriptionHistories}
    />
  );

  return (
    <PageContainer pageTitle="checkout.title" maxWidth="sm">
      <Box sx={{ marginTop: 2 }}>
        <Typography component="h1" variant="h5">
          {t("checkout.title")}
        </Typography>
        <Divider sx={{ marginTop: 2, marginBottom: 4 }} />

        {!product.price && <CircularProgress />}

        {!success && snackState.message === "" && (
          <section>
            <Box sx={{ marginTop: 2, marginBottom: 4 }}>
              <ProductCard />
            </Box>
            <Button variant="contained" onClick={confirmCheckout}>
              {t("action.confirm")}
            </Button>
          </section>
        )}
        {success && sessionId !== "" && (
          <section>
            <Box sx={{ marginTop: 2, marginBottom: 4 }}>
              <Alert severity="success">
                <AlertTitle>
                  {t("checkout.order_confirmtaion_title")}
                </AlertTitle>
                {t("checkout.order_confirmtaion_msg")}
              </Alert>
              <ProductCard />
            </Box>
          </section>
        )}
      </Box>

      <Snackbar
        open={snackState.open}
        message={snackState.message}
        autoHideDuration={3000}
        onClose={handleClose}
      />
    </PageContainer>
  );
}

export default Checkout;
