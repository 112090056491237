import { useCallback, useEffect, useState } from "react";
import { getPurchasedSubscription } from "@api/subscription";

export interface SubscriptionPlan {
  id: number;
  name: string;
  description: string;
  tier: string;
  price: number;
}

export interface SubscriptionHistory {
  id: number;
  productId: string;
  orderId: string;
  platform: string;
  subscription: SubscriptionPlan;
  created_at: string;
  expired_at?: string;
}

export const useSubscription = (userId?: number) => {
  const [subscriptionHistories, setSubscriptionHistories] = useState<
    SubscriptionHistory[]
  >([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activeTier, setActiveTier] = useState<string | null>(null);
  const activeSubscription =
    activeIndex !== null ? subscriptionHistories[activeIndex] : null;

  const fetch = useCallback(async () => {
    if (userId) {
      const { data } = await getPurchasedSubscription(userId);
      if (data) {
        setSubscriptionHistories(data);

        const lastIndex = data.length - 1;
        const active = data[lastIndex].subscription?.tier;
        setActiveIndex(lastIndex);
        setActiveTier(active);
      }
    }
  }, [userId]);

  const refetch = () => {
    fetch();
  };

  useEffect(() => {
    if (userId) {
      fetch();
    } else {
      setSubscriptionHistories([]);
      setActiveTier(null);
      setActiveIndex(null);
    }
    // eslint-disable-next-line
  }, [userId]);

  return {
    subscriptionHistories,
    activeTier,
    activeSubscription,
    refetch,
  };
};
